import axios from "axios";
import { SERVER_BASE_URL, SERVER_USER_URL } from "../utils/constant";

const UserAPI = {

    myInfo: async (token) => {
      try {
        const response = await axios.get(
          `${SERVER_USER_URL}/myInfo`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": token
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    getDemoToken: async () => {
      try {
        const response = await axios.get(
          `${SERVER_USER_URL}/get-demo-token`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    addLeagueFavorite: async (leagueId) => {
      try {
        const response = await axios.get(
          `${SERVER_USER_URL}/favorites/leagues/${leagueId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    addEventFavorite: async (eventId) => {
      try {
        const response = await axios.get(
          `${SERVER_USER_URL}/favorites/events/${eventId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    addMarketTypeFavorite: async (marketType) => {
      try {
        const response = await axios.get(
          `${SERVER_USER_URL}/favorites/marketTypes/${marketType}`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    fetchFavorites: async () => {
      try {
        const response = await axios.get(
          `${SERVER_USER_URL}/user/favorites`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },


  };
  
  export default UserAPI;
  
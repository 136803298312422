// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
}

.logout {
  text-align: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.logout h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.logout p {
  font-size: 16px;
  color: #555;
}`, "",{"version":3,"sources":["webpack://./src/views/LoggedOut/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,uCAAuC;AACzC;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":["body, html {\n  height: 100%;\n  margin: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-family: Arial, sans-serif;\n  background-color: #f4f4f4;\n}\n\n.logout {\n  text-align: center;\n  background-color: white;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.logout h1 {\n  font-size: 24px;\n  color: #333;\n  margin-bottom: 10px;\n}\n\n.logout p {\n  font-size: 16px;\n  color: #555;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

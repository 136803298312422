import Modal from 'react-modal';
import React, { useState, useEffect } from 'react';
import PropTypes, { func } from "prop-types";
import { useSelector } from 'react-redux';
import { TR, getSportTitle } from 'src/lib/utils/common';
import api from 'src/lib/api';
var moment = require('moment'); // require

const SportTab = ({sportId, handleChangeSport, isLive}) => {
    const NavData = useSelector((state) => isLive === true?state.sockets.LiveEvents: state.sockets.NavData);
    let [sportData, setSportData] = useState([]);

    let [initStatus, setInitStatus] = useState(0);

    let [totalV1, settotalV1] = useState(0);
    let [totalV2, settotalV2] = useState(0);
    
    let [totalV3, settotalV3] = useState(0);

    useEffect(() => {
        fetchSports();
    }, []);
    
    const fetchSports = async ()=>{
        let sportData1 = await api.post('/bethistory-api/all-sports', {pageIndex: 1, pageSize: 5});
        let sData = sportData1.data.data;
        
        setSportData(sData);

        for(let i=0; i < NavData.length; i++){
            let item = NavData[i];
            for(let j=0; j < sportData.length; j++){
                let ritem = sportData[j];
                if(item['SportName'] == ritem['name'])
                {
                    item['show_name'] = ritem['show_name'];
                    item['on_off'] = ritem['on_off'];
                    item['m_order'] = ritem['m_order'];
                }
            }
            if(item['show_name'] == null){
                item['show_name'] = item['SportName'];
                if(item['SportName_ko'] !== null && item['SportName_ko'] !== undefined)
                    item['show_name'] = item['SportName_ko'];
                item['on_off'] = 1;
                item['m_order'] = 1000;
            }
        }

        for(let i=0; i < NavData.length-1; i++)
            for(let j=i+1;j<NavData.length;j++)
                if(NavData[i]['m_order'] > NavData[j]['m_order'])
                {
                    let item = NavData[i];
                    NavData[i] = NavData[j];
                    NavData[j] = item;
                }

                setTimeout(() => {
                    addScript2();
                }, 2000);
    }

    function addScript2()
    {
        console.log('addscript2');
        const script = document.createElement("script");
            script.src = "js/script4.js";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script)
            }
    }

    function addScript1()
    {
        const allOrangeJuiceByClass = document.getElementsByClassName("line");
        for (let i = 0; i < allOrangeJuiceByClass.length; i++) {
            allOrangeJuiceByClass[i].id ='line' + i;
        }

        for(let i=0;i<3;i++)
        {
            let lineI = document.getElementById("line" + i);
            //console.log('addscript1');
            if(lineI)
                lineI.className = "line";

            /*if(i == 2 && lineI)
            {
                if(lineI.style.width == '0px')
                    lineI.style.width = '41px';
            }*/
        }
    }

    function addScript()
    {
        var tests = document.getElementsByClassName('left_hide_list');
        if(tests.length > 1 && initStatus == 0)
        {
            setInitStatus(1);

            console.log('add script2');

            const script = document.createElement("script");
            script.src = "js/script2.js";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script)
            }
        }

        addScript1();
    }

    function renderAll(){
        let totalCount = 0;
        
        //console.log(NavData);
        
        if(NavData.length > 0){
            for(let j=0; j < sportData.length; j++){
                let ritem = sportData[j];
                //console.log(ritem);
                if(parseInt(ritem['on_off']) == 1)
                {
                    let found = 0;
                    for(let i=0; i < NavData.length; i++){
                        let item = NavData[i];
                        if(item['SportId'] == ritem['sid'])
                            found++;
                    }

                    if(found == 0)
                    {
                        //console.log('should insert');
                        let item = {SportId: ritem['sid'], SportName: ritem['name'], SportName_ko: ritem['show_name'], eventsQuantity: 0, liveEventsQuantity: 0, m_order:ritem['m_order'], on_off:ritem['on_off']};
                        
                        NavData.push(item);
                    }
                }
            }

            for(let i=0; i < NavData.length; i++){
                let item = NavData[i];
                //console.log(item);
            }
        }

        for(let i=0; i < NavData.length; i++){
            let item = NavData[i];
            if(sportData != null){
                for(let j=0; j < sportData.length; j++){
                    let ritem = sportData[j];
                    if(item['SportName'] == ritem['name'])
                    {
                        item['show_name'] = ritem['show_name'];
                        item['on_off'] = ritem['on_off'];
                        item['m_order'] = ritem['m_order'];
                    }
                }
                if(item['show_name'] == null){
                    item['show_name'] = item['SportName'];
                    if(item['SportName_ko'] !== null && item['SportName_ko'] !== undefined){
                        item['show_name'] = item['SportName_ko'];
                    }
                    item['on_off'] = 1;
                    item['m_order'] = 1000;
                }
            }
            totalCount += isLive===true?item['liveEventsQuantity']: item['eventsQuantity'];
        }

        for(let i=0; i < NavData.length-1; i++)
            for(let j=i+1;j<NavData.length;j++)
                if(NavData[i]['m_order'] > NavData[j]['m_order'])
                {
                    let item = NavData[i];
                    NavData[i] = NavData[j];
                    NavData[j] = item;
                }

        
        if(NavData.length > 0 && totalV1 === 0 && totalV2 === 0){
            settotalV1(0);
            settotalV2(0);

            let totalVV1 = 0;
            let totalVV2 = 0;
            for(let i=0; i < NavData.length; i++)
            {
                let item = NavData[i];
                if(isLive)
                    totalVV1 += parseInt(item['liveEventsQuantity']);
                else
                    totalVV2 += parseInt(item['eventsQuantity']);
                //console.log('totalVV2',totalVV2, item['eventsQuantity']);
            }
            settotalV1(totalVV1);
            settotalV2(totalVV2);
            //console.log('totalV2',totalV2);
        }
    }

    return (
        <div className="sports_tab_w">
            <ul className="sub_tab sports_tab">
                {
                    renderAll()
                }
            </ul>

            <div className="contents_box_left">
                <div className="left_sports_box">
                        <ul>
                            <li className={sportId == -1?"active":""} onClick={()=>handleChangeSport(-1)}><a>전체<img src="images/pc/left_arrow.png" className="left_arrow"/></a></li>
                            <div className="left_hide_list" style={{display:"none"}}>
                            <ul>
                                <li className="active"><a ><img src="images/pc/@country.png" className="left_country_img"/>세계 <span className="left_sports_odd">{isLive===true?totalV1:totalV2}</span></a></li>
                            </ul>
                            </div>
                        </ul>
                    {
                        NavData.map((item, index) => (
                            item['on_off'] == 1 && 
                        <ul key={index}>
                            <li className={sportId == parseInt(item['SportId'])?"active":""} onClick={()=>handleChangeSport(item['SportId'])}><a><img src={"assets/images/sports/"+item.SportName+".svg"} className="left_icon"/>{TR(item, 'show_name')} <img src="images/pc/left_arrow.png" className="left_arrow"/></a></li>
                            <div className="left_hide_list" style={{display:"none"}}>
                            <ul>
                                <li className="active"><a ><img src="images/pc/@country.png" className="left_country_img"/>세계 <span className="left_sports_odd">{isLive===true?item['liveEventsQuantity']: item['eventsQuantity']}</span></a></li>
                            </ul>
                            </div>
                        </ul>
                        ))
                    }
                    {
                        addScript()
                    }
                </div>
            </div> 

        </div>
    )
}

SportTab.propTypes = {
    sportId : PropTypes.string.isRequired,
    handleChangeSport: PropTypes.func.isRequired,
    isLive : PropTypes.bool,
};

export default React.memo(SportTab)

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes, { func } from "prop-types";
import SportAPI from 'src/lib/api/sport';
import { MARKET_MENUS, MARKET_MENUS1, MARKET_MENU_ID, MARKET_TYPES, VIEW_MODES_ID } from 'src/lib/utils/constant';
import UserAPI from 'src/lib/api/user';
import { Tooltip } from 'react-tooltip';
import api from 'src/lib/api';
import { TR, awayTeam, displayRunner, displayRunner1, homeTeam } from '../../../lib/utils/common';
import moment from 'moment';
import { array } from 'prop-types';
var timerId = null;


const EventItem = (props) => {
    const dispatch = useDispatch();
    const {
        fixtureId,
        setLoading,
        loading
    } = props;
    const betslip = useSelector((state) => state.betslip.betslip);
    const[tab, setTab] = useState(0);
    const[flag, setFlag] = useState(false);
    const [eventInfo, setEventInfo] = useState({Participants: [{}, {}]});
    const [marketDict, setMarketDict] = useState({});
    const [aMarketDict, setAMarketDict] = useState([]);
    const [combinations, setCombinations] = useState([]);
    const [filteredMarketDict, setFilteredMarketDict] = useState({});

    const[prevSSid, setPrevSSid] = useState(0);
    
    useEffect(() => {
      setTab(0);
      fetchMarkets(true);

      document.getElementById('myWidget').style.display = "none";
  }, [fixtureId]);

    useEffect(() => {

      let tempMarketDict = {};
      Object.keys(marketDict).map((key, index)=>{
          if(MARKET_TYPES[tab].includes(parseInt(key)) || tab === MARKET_MENU_ID.ALL_MARKETS){
              tempMarketDict[key] = marketDict[key];
          }
      });
      setFilteredMarketDict(tempMarketDict);

      let total_count = 0;
      while (MARKET_MENUS1.length > 0) {
        MARKET_MENUS1.pop();
      }

      if(fixtureId != '')
        MARKET_MENUS1.push({id: MARKET_MENU_ID.ALL_MARKETS, name:'전체'});

      let fv = 0;
      MARKET_TYPES[MARKET_MENU_ID.MAIN].forEach(function(mt){
          Object.keys(marketDict).map((key, index1)=>{
              if(parseInt(mt) == parseInt(key))
                fv++;
          });
      });
      if(fv > 0){
        MARKET_MENUS1.push({id: MARKET_MENU_ID.MAIN, name:'승무패'});
        total_count = total_count - fv;
      }

      fv = 0;
      MARKET_TYPES[MARKET_MENU_ID.HALVES].forEach(function(mt){
          Object.keys(marketDict).map((key, index1)=>{
              if(parseInt(mt) == parseInt(key))
                fv++;
          });
      });
      if(fv > 0){
        MARKET_MENUS1.push({id: MARKET_MENU_ID.HALVES, name:'핸디캡'});
        total_count = total_count - fv;
      }

      fv = 0;
      MARKET_TYPES[MARKET_MENU_ID.PLAYER].forEach(function(mt){
          Object.keys(marketDict).map((key, index1)=>{
              if(parseInt(mt) == parseInt(key))
                fv++;
          });
      });
      if(fv > 0){
        MARKET_MENUS1.push({id: MARKET_MENU_ID.PLAYER, name:'언더오버'});
        total_count = total_count - fv;
      }

      fv = 0;
      MARKET_TYPES[MARKET_MENU_ID.GOALS].forEach(function(mt){
          Object.keys(marketDict).map((key, index1)=>{
              if(parseInt(mt) == parseInt(key))
                fv++;
          });
      });
      if(fv > 0){
        MARKET_MENUS1.push({id: MARKET_MENU_ID.GOALS, name:'홀짝'});
        total_count = total_count - fv;
      }

      fv = 0;
      MARKET_TYPES[MARKET_MENU_ID.CORNERS].forEach(function(mt){
          Object.keys(marketDict).map((key, index1)=>{
              if(parseInt(mt) == parseInt(key))
                fv++;
          });
      });

      if(fv > 0)
        MARKET_MENUS1.push({id: MARKET_MENU_ID.CORNERS, name:'기타'});

      if (MARKET_MENUS1.length == 1)
        MARKET_MENUS1.pop();
    }, [marketDict]);

    useEffect(() => {
      if(loading === false){
        fetchMarkets(false);
      }
    }, [flag]);

    useEffect(() => {
      let tempMarketDict = {};
      Object.keys(marketDict).map((key, index)=>{
          if(MARKET_TYPES[tab].includes(parseInt(key)) || tab === MARKET_MENU_ID.ALL_MARKETS){
              tempMarketDict[key] = marketDict[key];
          }
      });
      setFilteredMarketDict(tempMarketDict);
    }, [tab]);

    const fetchMarkets = async (isShowLoading = true)=>{
        try{
            if(fixtureId === '') return;
            if(isShowLoading === true) setLoading(true);
            let marketDict = {};
            let response = await api.get(`/sport-api/market-view?fixtureid=${fixtureId}&type=0`);
            response = response.data;
            setEventInfo(response['fixture']);
            setAMarketDict(response['aMarkets']);
            setMarketDict(response['data']);
            setCombinations(response['combinations']);


            if(eventInfo['SsId'] != prevSSid && eventInfo['SsId'] != null){
              console.log('ssid different');
              window.STATSCOREWidgets.onLoad(err => {
                if (err) {
                    switch (err.type) {
                        case 'NetworkError':
                            // Handle network error here
                            break;
        
                        case 'BrowserNotSupported':
                            // Handle unsupported browser here
                            break;
                    }
        
                    return;
                }
        
                // Widget will be appended to this HTMLElement.
                //
                // If you are using framework then follow its documentation
                // on how to get DOM Element from your component.
                // React https://en.reactjs.org/docs/refs-and-the-dom.html
                const element = document.getElementById('myWidget');
        
                // Configuration that you should receive from STATSCORE
                let configurationId = '667137537d4a56840df4c420';
                // Input data for widget type you want to embded
        
                if(parseInt(eventInfo['SportId']) == 6046)
                  configurationId = '667e5e3ede34f31fd81f1182';
                if(parseInt(eventInfo['SportId']) == 48242)
                  configurationId = '667e58f1de34f31fd81f1181';
                if(parseInt(eventInfo['SportId']) == 35232)
                  configurationId = '667e5e978e775c9378f0d675';
                if(parseInt(eventInfo['SportId']) == 154914)
                  configurationId = '667e5f038e775c9378f0d676';
                  
                const inputData = { eventId: eventInfo['SsId'], language: 'en' };
                //const inputData = { eventId: 4948851, language: language };
                // Optional object with options.
                // You can check available options further in the docs.
                const options = {};
        
                const widget = new window.STATSCOREWidgets.WidgetGroup(element, configurationId, inputData, options);
                console.log(widget);
              });

              setPrevSSid(eventInfo['SsId']);

              document.getElementById('myWidget').style.display = "block";
            }

            if(eventInfo['SsId'] == null)
                  setPrevSSid(0);
                  
        }catch(ex){
            console.log(ex);
        }
        if(isShowLoading === true) setLoading(false);
        setTimeout(function(){
          setFlag(!flag)
        }, 5000);
    }

    const convTR = (item) => {
      if(parseInt(eventInfo['SportId']) == 154914)
        return item.replace('피리어드', '이닝');
      else if(parseInt(eventInfo['SportId']) == 48242)
        return item.replace('피리어드', '쿼터');
      else
        return item;
    }

    const nbetCell = (item) => {
      try{
        let {Code, FixtureId, LeagueId, LeagueName, LocationId, LocationName, Participants, SportId, SportName} = eventInfo;
        let eventData = {
            Code, FixtureId, 
            LeagueId, LeagueName, 
            LocationId, LocationName, 
            Participants: Participants, 
            SportId, SportName
        };
        let isSelected = false;
        betslip.forEach(function(betItem){
            if(betItem['Selection']['Id'] === item['Id']) isSelected = true;
        });
        let betId = FixtureId + '-' + item['MarketId'] + '-' + item['Id'];
        let slipData = {
            Id: betId,
            Fixture: eventData,
            Selection: item,
        }
        return <div className={"details_bet_btn" + (isSelected?" active": "")} key={item['Id']} onClick={()=>dispatch({ type: 'addBet', data: slipData})}>
          <div className="dbb_text1">{displayRunner1(item['Name'], eventInfo)}
            {
              (item['Name'] === 'Over' || item['Name'] === 'Under') &&
            <i className={(item['Name'] ==='Over'?"ar1":"ar2")}></i>
            }
            {
              (item['MarketName'].includes('Handicap') === true) &&
            <i className={"h"}></i>
            }

            {
              item['BaseLine'] && <b>({item['BaseLine'].split(' ')[0]})</b>
            }
          </div>
          <div className="dbb_odd">{item['Price']}</div>
        </div>
      }catch{

      }
      return <></>
    }

    const nbetCell1 = (item) => {
      try{
        let {Code, FixtureId, LeagueId, LeagueName, LocationId, LocationName, Participants, SportId, SportName} = eventInfo;
        let eventData = {
            Code, FixtureId, 
            LeagueId, LeagueName, 
            LocationId, LocationName, 
            Participants: Participants, 
            SportId, SportName
        };
        let isSelected = false;
        betslip.forEach(function(betItem){
            if(betItem['Selection']['Id'] === item['Id']) isSelected = true;
        });
        let betId = FixtureId + '-' + item['MarketId'] + '-' + item['Id'];
        let slipData = {
            Id: betId,
            Fixture: eventData,
            Selection: item,
        }
        return <div className={"details_bet_btn" + (isSelected?" active": "")} key={item['Id']}>
          <div className="dbb_text1">{displayRunner1(item['Name'], eventInfo)}
            {
              (item['Name'] === 'Over' || item['Name'] === 'Under') &&
            <i className={(item['Name'] ==='Over'?"ar1":"ar2")}></i>
            }
            {
              (item['MarketName'].includes('Handicap') === true) &&
            <i className={"h"}></i>
            }

            {
              item['BaseLine'] && <b>({item['BaseLine'].split(' ')[0]})</b>
            }
          </div>
          <div className="dbb_odd"><img src="images/pc/icon_lock.png" /></div>
        </div>
      }catch{

      }
      return <></>
    }

    const betCell = (item) => {
      try{
        let {Code, FixtureId, LeagueId, LeagueName, LocationId, LocationName, Participants, SportId, SportName} = eventInfo;
        let eventData = {
            Code, FixtureId, 
            LeagueId, LeagueName, 
            LocationId, LocationName, 
            Participants: Participants, 
            SportId, SportName
        };
        let isSelected = false;
        betslip.forEach(function(betItem){
            if(betItem['Selection']['Id'] === item['Id']) isSelected = true;
        });
        let betId = FixtureId + '-' + item['MarketId'] + '-' + item['Id'];
        let slipData = {
            Id: betId,
            Fixture: eventData,
            Selection: item,
        }
        return <li style={{cursor:"pointer"}} key={item['Id']} className={isSelected?"selected": ""} onClick={()=>dispatch({ type: 'addBet', data: slipData})}>
          <span className="in_team">{displayRunner(item['Name'])} 
            {
              (item['Name'] === 'Over' || item['Name'] === 'Under') &&
            <i className={(item['Name'] ==='Over'?"ar1":"ar2")}></i>
            }
            {
              (item['MarketName'].includes('Handicap') === true) &&
            <i className={"h"}></i>
            }

            {
              item['BaseLine'] && <b>({item['BaseLine']})</b>
            }
          </span>
          <span className="in_rate tt_o">{item['Price']}</span>
        </li>
      }catch{

      }
      return <></>
    }

    const betCell1 = (item) => {
      try{
        let {Code, FixtureId, LeagueId, LeagueName, LocationId, LocationName, Participants, SportId, SportName} = eventInfo;
        let eventData = {
            Code, FixtureId, 
            LeagueId, LeagueName, 
            LocationId, LocationName, 
            Participants: Participants, 
            SportId, SportName
        };
        let isSelected = false;
        betslip.forEach(function(betItem){
            if(betItem['Selection']['Id'] === item['Id']) isSelected = true;
        });
        let betId = FixtureId + '-' + item['MarketId'] + '-' + item['Id'];
        let slipData = {
            Id: betId,
            Fixture: eventData,
            Selection: item,
        }
        return <li style={{cursor:"pointer"}} key={item['Id']} className={isSelected?"selected": ""}>
          <span className="in_team">{displayRunner(item['Name'])} 
            {
              (item['Name'] === 'Over' || item['Name'] === 'Under') &&
            <i className={(item['Name'] ==='Over'?"ar1":"ar2")}></i>
            }
            {
              (item['MarketName'].includes('Handicap') === true) &&
            <i className={"h"}></i>
            }

            {
              item['BaseLine'] && <b>({item['BaseLine']})</b>
            }
          </span>
          <span className="in_rate tt_o">
            <img src="http://219.127.148.176:8005/images/lock.png" style={{"margin-top":10}} width="15" height="19" alt="" />
          </span>
        </li>
      }catch{

      }
      return <></>
    }

    function renderMarkets(){

      var array = [];
        if(eventInfo['combination'] != null)
            array = eventInfo['combination'].split(",");

      let rows = [];
      Object.keys(filteredMarketDict).forEach(function(key){
        let same = 0;
        if(array.length > 0){
          for(let i=0;i<array.length;i++)
            if(array[i] == key)
              same = 1;
        }
        else
          same = 1;
        
          let show = 0;
          let s_count = 0;
          aMarketDict.forEach(function(item){
            if(item['market'] == key){
              s_count++;
              if(item['on_off'] == 1)
                show = 1;
            }
          });
          if(aMarketDict.length == 0 || s_count == 0)
            show = 1;

        let subList = [];
        if(Array.isArray(filteredMarketDict[key])){
          subList = filteredMarketDict[key];
          let runnerList = subList;
          /*runnerList.sort(function(a, b){
            return a.Name > b.Name ? 1:-1
          });*/
          
          let subrows = [];
          if(runnerList.length > 3){
            for(let i =0; i < runnerList.length; i=i+2){
              show == 1 && subrows.push(
                <ul key={i} className={`bet col2`} style={{display:"block"}}>
                      {same==1&&betCell(runnerList[i])}
                      {same==1&&betCell(runnerList[i+1])}
                      {same==0&&betCell1(runnerList[i])}
                      {same==0&&betCell1(runnerList[i+1])}
                </ul>
              )
            }
          }
          if(runnerList.length > 1){
            show == 1 && rows.push(
              <>
                <ul className="tit" style={{display:"block"}}>{subList[0]['MarketName']}-{subList[0]['MarketId']}</ul>
                <ul className={`bet col${runnerList.length}`} style={{display:"block"}}>
                  {
                    runnerList.length <= 3 && runnerList.map((runner, index) => (
                      <>{same==1&&betCell(runner)}
                      {same==0&&betCell1(runner)}</>
                    ))
                  }
                </ul>
                {
                  subrows
                }
              </>
            )
          }
        }else{
          subList = Object.values(filteredMarketDict[key]);
          subList = subList.sort(function(a, b){
            try{
              return parseFloat(Object.values(a)[0]['BaseLine']) > parseFloat(Object.values(b)[0]['BaseLine']) ? 1:-1
            }catch{
            }
            return true;
          });
          show == 1 && rows.push(
            <>
              <ul className="tit" style={{display:"block"}}>{Object.values(subList[0])[0]['MarketName']}-{Object.values(subList[0])[0]['MarketId']}</ul>
              {
                subList.map((subitem, index) => {
                  let runnerList = Object.values(subitem);
                  /*runnerList.sort(function(a, b){
                    return a.Name > b.Name ? 1:-1
                  });*/
                  let rows = [];
                  if(runnerList.length > 3){
                    for(let i =0; i < runnerList.length; i=i+2){
                      rows.push(
                        <ul key={index} className={`bet col2`} style={{display:"block"}}>
                              {same==1&&betCell(runnerList[i])}
                              {same==1&&betCell(runnerList[i+1])}
                              {same==0&&betCell1(runnerList[i])}
                              {same==0&&betCell1(runnerList[i+1])}
                        </ul>
                      )
                    }
                    return rows;
                  }else if(runnerList.length > 1){
                    return <ul key={index} className={`bet col${runnerList.length}`} style={{display:"block"}}>
                            {
                              runnerList.map((runner, index) => (
                                <>{same==1&&betCell(runner)}
                                {same==0&&betCell1(runner)}</>
                              ))
                            }
                          </ul>
                  }
              })
              }
              
            </>
          )
        }
        

      })        
      return rows;
    }

    function renderMarkets1(){

      let rows = [];
      Object.keys(filteredMarketDict).forEach(function(key){
        let same = 0;
        
        if(betslip.length == 0)
          same = 1;
        else{
          let foundm = 0;
          betslip.forEach(function(betItem){
            //console.log(betItem['Selection']['MarketId'], key);
            if(parseInt(betItem['Selection']['MarketId']) === parseInt(key)) {
              same = 1;
              foundm++;
            }
          });
          
          if(foundm == 0){
            if(combinations.length > 0){
              for(let ii=0;ii<combinations.length;ii++){
                if(eventInfo['SportId'] === combinations[ii]['sport']){
                  let array = combinations[ii]['combination'].split(",");

                  console.log(array);

                  for(let i=0;i<array.length;i++){
                    if(parseInt(array[i]) == parseInt(key)){
                      betslip.forEach(function(betItem){
                        for(let m=0;m<array.length;m++)
                          if(parseInt(betItem['Selection']['MarketId']) === parseInt(array[m]))
                            same = 1;
                      })
                    }
                  }
                }
              }
            }
            else
              same = 1;
          }

        }

          let show = 0;
          let s_count = 0;
          aMarketDict.forEach(function(item){
            if(item['market'] == key){
              s_count++;
              if(item['on_off'] == 1)
                show = 1;
            }
          });
          if(aMarketDict.length == 0 || s_count == 0)
            show = 1;

        let subList = [];
        if(Array.isArray(filteredMarketDict[key])){
          subList = filteredMarketDict[key];
          let runnerList = subList;
          /*runnerList.sort(function(a, b){
            return a.Name > b.Name ? 1:-1
          });*/
          
          let subrows = [];
          if(runnerList.length > 3){
            for(let i =0; i < runnerList.length; i=i+2){
              show == 1 && subrows.push(
                <div className={"details_bet_btn_box w" + (runnerList.length==3?"33":"55") + (same==1?"":" bet_lock")}>
                        {same==1&&nbetCell(runnerList[i])}
                        {same==1&&nbetCell(runnerList[i+1])}
                        {same==0&&nbetCell1(runnerList[i])}
                        {same==0&&nbetCell1(runnerList[i+1])}
                </div>
              )
            }
          }
          if(runnerList.length > 1){
            show == 1 && rows.push(
              <>
                    <div className="details_bet_list">
                      <div className="details_bet_list_title active"><span className="s_icon">◈</span>{' ' + convTR(TR(subList[0], 'MarketName'))}<img src="images/pc/left_arrow.png" /></div>
                      <div className={"details_bet_btn_box w" + (runnerList.length==3?"33":"55") + (same==1?"":" bet_lock")}>
                          {
                            runnerList.length <= 3 && runnerList.map((runner, index) => (
                              <>{same==1&&nbetCell(runner)}
                              {same==0&&nbetCell1(runner)}</>
                            ))
                          }
                        {
                          subrows
                        }
                      </div>
                  </div>
              </>
            )
          }
        }else{
          subList = Object.values(filteredMarketDict[key]);
          subList = subList.sort(function(a, b){
            try{
              return parseFloat(Object.values(a)[0]['BaseLine']) > parseFloat(Object.values(b)[0]['BaseLine']) ? 1:-1
            }catch{
            }
            return true;
          });
          let subList1 = Object.values(subList[0]);
          subList1.length > 1 && show == 1 && rows.push(
            <>
              <div className="details_bet_list_title active"><span className="s_icon">◈</span>{' ' + convTR(TR(Object.values(subList[0])[0], 'MarketName'))}<img src="images/pc/left_arrow.png" /></div>
              {
                subList.map((subitem, index) => {
                  let runnerList = Object.values(subitem);
                  /*runnerList.sort(function(a, b){
                    return a.Name > b.Name ? 1:-1
                  });*/
                  let rows = [];
                  if(runnerList.length > 3){
                    for(let i =0; i < runnerList.length; i=i+2){
                      rows.push(
                        <div key={index} className={"details_bet_btn_box w" + (runnerList.length==3?"33":"55") + (same==1?"":" bet_lock")}>
                                {same==1&&nbetCell(runnerList[i])}
                                {same==1&&nbetCell(runnerList[i+1])}
                                {same==0&&nbetCell1(runnerList[i])}
                                {same==0&&nbetCell1(runnerList[i+1])}
                        </div>
                      )
                    }
                    return rows;
                  }else if(runnerList.length > 1){
                    return <div key={index} className={"details_bet_btn_box w" + (runnerList.length==3?"33":"55") + (same==1?"":" bet_lock")}>
                            {
                              runnerList.map((runner, index) => (
                                <>{same==1&&nbetCell(runner)}
                                {same==0&&nbetCell1(runner)}</>
                              ))
                            }
                          </div>
                  }
              })
              }
              
            </>
          )
        }
        

      })        
      return rows;
    }


    return (
              <div className="cbr_btn_content active">
                <div className="details_bet_box">
                  <a className="cbr_btn sports_list_toggle">닫기</a>
                  <div className="sports_video" id="myWidget">영상구역</div>
                  <div className="cbr_tab_box padding_none">
                    <div className="cbr_tab left nav1">
                      <ul>
                      {
                          MARKET_MENUS1.map((item, index)=>(
                              <li key={index} className={tab === item['id']?"active":""}><a onClick={()=>setTab(item['id'])}>{item['name']}</a></li>
                          ))
                      }
                      </ul>
                    </div>
                  </div>
                  <div className="details_bet_list_box">
                    {
                      renderMarkets1()
                    }
                  </div>
                </div>
              </div>
    )
}

EventItem.propTypes = {
    fixtureId: PropTypes.string.isRequired,
    setLoading: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
  
export default React.memo(EventItem)

import axios from 'axios';
import { SERVER_URL } from './utils/constant';
import { getLangCode } from './utils/common';

const api = axios.create({
    baseURL: `${SERVER_URL}/`,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add a request interceptor
api.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.headers['lang'] = getLangCode();
    let accessToken = '';
    try{
        accessToken = localStorage.getItem('accessToken');
    }catch{
    }
    config.headers['auth-token'] = accessToken;
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
  
// Add a response interceptor
api.interceptors.response.use(function (response) {
    if(response.status === 403 || response.status === 500){
        localStorage.removeItem('user');
        // window.location.href = '/login';
    };
    return response;
  }, function (error) {
    // Do something with response error
    if(error.response.status === 403 || error.response.status === 500){
        localStorage.removeItem('user');
        // window.location.href = '/login';
    };
    return Promise.reject(error);
  });


export default api;
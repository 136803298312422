import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from "prop-types";
import SportAPI from 'src/lib/api/sport';
import { VIEW_MODES_ID } from 'src/lib/utils/constant';
import UserAPI from 'src/lib/api/user';
import { Tooltip } from 'react-tooltip';
import api from 'src/lib/api';
import { TR, awayTeam, getSportEnTitle, getSportTitle, homeTeam, displayRunner } from '../../../lib/utils/common';
import moment from 'moment';
import logger from 'redux-logger';

const LeagueItem = (props) => {
    const dispatch = useDispatch();
    const viewMode  = useSelector((state) => state.global.viewMode);
    const {
        data,
        combinations,
        defaultExpanded,
        viewType, 
        date,
        selFixtureId,
        handleSelectEvent
    } = props;
    const betslip = useSelector((state) => state.betslip.betslip);
    const favLeagues = useSelector((state) => state.favorite.favLeagues);
    const[firstLoad, setFirstLoad] = useState(false);
    const[flag, setFlag] = useState(true);
    const[flagAlt, setFlagAlt] = useState(true);
    const[loading, setLoading] = useState(false);
    const[expanded, setExpanded] = useState(true);
    const[leagueData, setLeagueData] = useState(data);
    const[combination, setCombination] = useState(combinations);

    const[showV, setShowV] = useState(0);

    useEffect(() => {  
        for(let i=0; i < data.length-1; i++)
            for(let j=i+1;j<data.length;j++)
                if(data[i]['m_order'] > data[j]['m_order'])
                {
                    let item = data[i];
                    data[i] = data[j];
                    data[j] = item;
                }
        
                for(let id1 = 0;id1 < data.length; id1++)
                {
                    let item = data[id1];
                    let itemData = Object.values(item['MarketDict']);
                    let itemDataKey = Object.keys(item['MarketDict']);
        
                    for(let i=0;i<itemData.length;i++)
                    {
                        let is_sort = 0;
                        let marketArr = Object.values(itemData[i]);
                        let marketArrKey = Object.keys(itemData[i]);
                        marketArr.forEach(function(key){
                            let keyData = Object.values(key);
                            if(keyData.length == 2){
                                is_sort++;
                            }
                        });
        
                        if(is_sort == marketArr.length && marketArr.length > 1){
                            //console.log(item);
                            //console.log(data[id1]['MarketDict'][itemDataKey[i]][marketArrKey[marketArrKey.length - 1]]);
        
                            for(let j=0;j<marketArr.length-1;j++)
                                for(let k=j+1;k<marketArr.length;k++)
                                    {
                                        let jVData = Object.values(data[id1]['MarketDict'][itemDataKey[i]][marketArrKey[j]]);
                                        let kVData = Object.values(data[id1]['MarketDict'][itemDataKey[i]][marketArrKey[k]]);
                                        let jV = Math.abs(parseFloat(jVData[0].Price) - parseFloat(jVData[1].Price));
                                        let kV = Math.abs(parseFloat(kVData[0].Price) - parseFloat(kVData[1].Price));
                                        if(jV < kV)
                                        {
                                            let tmp = data[id1]['MarketDict'][itemDataKey[i]][marketArrKey[j]];
                                            data[id1]['MarketDict'][itemDataKey[i]][marketArrKey[j]] = data[id1]['MarketDict'][itemDataKey[i]][marketArrKey[k]];
                                            data[id1]['MarketDict'][itemDataKey[i]][marketArrKey[k]] = tmp;
                                        }
                                    }
                            //console.log(data[id1]['MarketDict'][itemDataKey[i]][marketArrKey[marketArrKey.length - 1]]);
                        }
                    }
                };

        setLeagueData(data);

        /*const script = document.createElement("script");
        script.src = "js/script.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script)
        }*/

    }, [data]);
    
    useEffect(() => {  
        setCombination(combinations);
    }, [combinations]);

    function getOdds(marketData, market, runner){
        let odds = 'N/A';
        try{
            marketData[market].forEach(element => {
                if(element['Name'] === runner) odds = element['Price']
            });
        }catch{
        }
        return odds
    }

    function nGetOdds(marketData, market, runner){
        let odds = 'N/A';

        if(newGetOdds(marketData, market, runner) == 3){
            try{
                marketData[market].forEach(element => {
                    if(element['Name'] === runner) odds = element['Price']
                });
            }catch{
            }
        }
        else
        {
            if(marketData[market] != null){
                let subList = [];
                Object.keys(marketData[market]).forEach(function(key){
                    if(Array.isArray(marketData[market][key])){
                        subList = marketData[market][key];
                        //console.log(subList);
                    }
                    else
                    {
                        subList = Object.values(marketData[market][key]);
                        if(subList[runner - 1] != null){
                            //console.log(subList[0]['Price']);
                            odds = subList[runner - 1]['Price'];
                        }
                    }
                })
            }
        }

        return odds
    }

    function newGetName(marketData, market, runner, homeTeam, awayTeam){
        let odds = "";
        let odds1 = "";

        if(marketData[market] != null){
            let subList = [];
            Object.keys(marketData[market]).forEach(function(key){
                odds = key;
                if(Array.isArray(marketData[market][key])){
                    subList = marketData[market][key];
                    //console.log(subList);
                }
                else
                {
                    subList = Object.values(marketData[market][key]);
                    if(subList[runner - 1] != null){
                        //console.log(subList[0]['Price']);
                        odds1 = subList[runner - 1]['Name'];
                    }
                }
            })
        }

        if(displayRunner(odds1) === '홈')
            return homeTeam;
        else if(displayRunner(odds1) === '원정')
            return awayTeam;
        else
            return displayRunner(odds1);
    }

    function newGetName1(marketData, market, runner){
        let odds = "";
        let odds1 = "";

        if(marketData[market] != null){
            let subList = [];
            Object.keys(marketData[market]).forEach(function(key){
                if(Array.isArray(marketData[market][key])){
                    subList = marketData[market][key];
                    //console.log(subList);
                }
                else
                {
                    subList = Object.values(marketData[market][key]);
                    if(subList[runner - 1] != null){
                        //console.log('newname', subList[runner - 1]['Line'], subList[runner - 1]);
                        if(subList[runner - 1]['Line'] != null)
                            odds = subList[runner - 1]['Line'].split(' ')[0];
                    }
                }
            })
        }

        return odds
    }

    function newGetOdds(marketData, market, runner){
        let odds = 2;
        try{
            marketData[market].forEach(element => {
                if(element['Name'] === '1' || element['Name'] === '2') odds = 3
            });
        }catch{
        }
        return odds
    }

    function runnerSelected(marketData, market, runner){
        try{
            let runnerItem = null;
            marketData[market].forEach(element => {
                if(element['Name'] === runner) runnerItem = element
            });
            if(runnerItem !== null){
                let isSelected = false;
                betslip.forEach(function(betItem){
                    if(betItem['Selection']['Id'] === runnerItem['Id']) isSelected = true;
                });
                return isSelected === true?'selected': '';
            }
        }catch{
        }
        return '';
    }
    
    function nRunnerSelected(marketData, market, runner){
        try{
            let runnerItem = null;
            marketData[market].forEach(element => {
                if(element['Name'] === runner) runnerItem = element
            });
            if(runnerItem !== null){
                let isSelected = false;
                betslip.forEach(function(betItem){
                    if(betItem['Selection']['Id'] === runnerItem['Id']) isSelected = true;
                });
                return isSelected === true?'active': '';
            }
        }catch{
        }
        return '';
    }

    function checkBetStatus(item, market){

        let result = 0;

        if(betslip.length == 0)
            result = 1;
        else
        {
            let fs_market = null;
            if(newGetOdds(item['MarketDict'], market, 'X') == 3)
            {
                if(item['MarketDict'][market] != null){
                    item['MarketDict'][market].forEach(element => {
                        fs_market = element['MarketId'];
                    });
                }
            }
            else
            {
                if(item['MarketDict'][market] != null){
                    Object.keys(item['MarketDict'][market]).forEach(function(key){
                        if(Array.isArray(item['MarketDict'][market])){
                            let subList = item['MarketDict'][market][key];
                        }
                        else
                        {
                            let subList = Object.values(item['MarketDict'][market][key]);
                            fs_market = subList[0]['MarketId'];
                        }
                    })
                }
            }

            if(fs_market != null){
                let foundm = 0;
                betslip.forEach(function(betItem){
                    if(parseInt(betItem['Selection']['MarketId']) === parseInt(fs_market)) {
            
                        result = 1;
                        foundm++;
                    }
                });

                if(foundm == 0){

                    combination.forEach(element => {
                        let combItem = null;
                        if(element['sport'] === item['SportId']){
                            combItem = element['combination'];
                        }
                
                        let array;
                        if(combItem != null){
                            array = combItem.split(",");
                            for(let i=0;i<array.length;i++)
                                if(parseInt(array[i]) == parseInt(fs_market)){
                                    betslip.forEach(function(betItem){
                                        for(let m=0;m<array.length;m++)
                                            if(parseInt(betItem['Selection']['MarketId']) === parseInt(array[m]))
                                                result = 1;
                                    })
                                }
                        }
                        else
                        {
                            result = 1;
                        }
                    })
                }
            }
        }

        if(result == 1)
            return '';
        else
            return ' bet_lock';
    }

    function newRunnerSelected(marketData, market, runner){
        try{
            let runnerItem = null;
            /*marketData[market].forEach(element => {
                if(element['Name'] === runner) runnerItem = element
            });*/

            if(newGetOdds(marketData, market, 'X') == 3)
            {
                marketData[market].forEach(element => {
                    if(element['Name'] === runner) runnerItem = element;
                });
            }
            else
            {
                Object.keys(marketData[market]).forEach(function(key){
                    if(Array.isArray(marketData[market])){
                        let subList = marketData[market][key];
                        //console.log(subList.length, runner)
                    }
                    else
                    {
                        let subList = Object.values(marketData[market][key]);
                        runnerItem = subList[runner - 1];
                        //console.log(subList.length, runner)
                    }
                })
            }

            if(runnerItem !== null){
                let isSelected = false;
                betslip.forEach(function(betItem){
                    if(betItem['Selection']['Id'] === runnerItem['Id']) isSelected = true;
                });
                return isSelected === true?'active': '';
            }
        }catch{
        }
        return '';
    }
    
    const placeBet = (eventInfo, market, runner) => {
        if(checkBetStatus(eventInfo, market) === ' bet_lock') return;

        let {Code, FixtureId, LeagueId, LeagueName, LocationId, LocationName, Participants, SportId, SportName} = eventInfo;
        
        let eventData = {
            Code, FixtureId, 
            LeagueId, LeagueName, 
            LocationId, LocationName, 
            Participants: Participants, 
            SportId, SportName
        };
        let runnerItem = null;
        
        if(newGetOdds(eventInfo['MarketDict'], market, 'X') == 3)
        {
            eventInfo['MarketDict'][market].forEach(element => {
                if(element['Name'] === runner) runnerItem = element;
            });
        }
        else
        {
            Object.keys(eventInfo['MarketDict'][market]).forEach(function(key){
                if(Array.isArray(eventInfo['MarketDict'][market])){
                    let subList = eventInfo['MarketDict'][market][key];
                }
                else
                {
                    let subList = Object.values(eventInfo['MarketDict'][market][key]);
                    runnerItem = subList[runner - 1];
                }
            })
        }

        if(runnerItem !== null){
            //console.log(runnerItem);
            let betId = FixtureId + '-' + runnerItem['MarketId'] + '-' + runnerItem['Id'];
            let slipData = {
                Id: betId,
                Fixture: eventData,
                Selection: runnerItem,
            }
            dispatch({ type: 'addBet', data: slipData})
        }
    }

    return (
        <>
        {
            leagueData.length > 0 &&
            <div className="sports_list_bundle">
                <div className="slb_title active">
                  {leagueData[0]['show_name'] == null && <div className="slb_country"><img src="images/pc/@country.png" /></div>}
                  {leagueData[0]['show_name'] != null && <div className="slb_league"><img style={{width: "24px"}} src={leagueData[0]['image_path']} /></div>}
                  <div className="slb_league"><img src={"assets/images/sports/" + getSportEnTitle(leagueData[0].SportId) + ".svg"} /></div>
                  <div className="slb_text1">{TR(leagueData[0],'LocationName')}</div>
                  <div className="slb_text2">
                    {leagueData[0]['show_name'] == null && TR(leagueData[0], 'LeagueName')}
                    {leagueData[0]['show_name'] != null && TR(leagueData[0], 'show_name')}
                  </div>
                  <div className="slb_text3">{moment(leagueData[0]['StartDate']).format('MM-DD HH:mm')}</div>
                  <div className="slb_arrow"><img src="images/pc/left_arrow.png" /></div>
                </div>
                    {
                        
                        <div className="sports_list_box">
                        {leagueData.map((item, index)=> (
                                <div className="sports_list" key={index}>
                                    <div className={"sports_list_title" + (selFixtureId === item['FixtureId']?' active': '')} onClick={()=>handleSelectEvent(item['FixtureId'])}>
                                    <div className="sport_title_team left">{homeTeam(item)}</div>
                                    <div className="sport_title_odd"><a>+ {item['MarketCount']}</a></div>
                                    <div className="sport_title_team right">{awayTeam(item)}</div>
                                    </div>
                                    
                                    {
                                        Object.values(item['MarketDict']).map((subitem, sindex)=> (
                                           
                                            nGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), '1') !== 'N/A' && nGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), '2') !== 'N/A' &&
                                        <div className={"sports_bet_list sbl" + newGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), 'X') + checkBetStatus(item, 'mainMarket' + (sindex + 1))} key={`${index}-${sindex}`}>
                                            <div className={"sports_bet_btn home " + newRunnerSelected(item['MarketDict'], 'mainMarket' + (sindex + 1), '1')}  onClick={()=>placeBet(item, 'mainMarket' + (sindex + 1), '1')}>
                                                <div className="sbb_left">
                                                {newGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), 'X') == 3 && <div className="sbb_team">{homeTeam(item)}</div>}
                                                {newGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), 'X') == 2 && <>
                                                    <div className="sbb_team">{newGetName(item['MarketDict'], 'mainMarket' + (sindex + 1), '1', homeTeam(item), awayTeam(item))}</div>
                                                    {newGetName(item['MarketDict'], 'mainMarket' + (sindex + 1), '1', homeTeam(item), awayTeam(item)) == '오버' && <div className="sbb_team_odd_icon"><img src="images/pc/arr1.png" /></div>}
                                                    {newGetName(item['MarketDict'], 'mainMarket' + (sindex + 1), '1', homeTeam(item), awayTeam(item)) == '언더' && <div className="sbb_team_odd_icon"><img src="images/pc/arr2.png" /></div>}
                                                    <div className="sbb_team_odd">{newGetName1(item['MarketDict'], 'mainMarket' + (sindex + 1), '1')}</div>
                                                </>}
                                                </div>
                                                <div className="sbb_right">
                                                {checkBetStatus(item, 'mainMarket' + (sindex + 1)) === '' && <div className="sbb_odd">{nGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), '1')}</div>}
                                                {checkBetStatus(item, 'mainMarket' + (sindex + 1)) === ' bet_lock' && <div className="sbb_odd"><img src="images/pc/icon_lock.png" /></div>}
                                                </div>
                                            </div>
                                            {newGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), 'X') == 3 && checkBetStatus(item, 'mainMarket' + (sindex + 1)) === '' && <div className={"sports_bet_btn and "  + newRunnerSelected(item['MarketDict'], 'mainMarket' + (sindex + 1), 'X')} onClick={()=>placeBet(item, 'mainMarket' + (sindex + 1), 'X')}><div className="sbb_odd">{nGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), 'X') === 'N/A'?'VS': nGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), 'X')}</div></div>}
                                            {newGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), 'X') == 3 && checkBetStatus(item, 'mainMarket' + (sindex + 1)) === ' bet_lock' && <div className={"sports_bet_btn and "  + newRunnerSelected(item['MarketDict'], 'mainMarket' + (sindex + 1), 'X')} onClick={()=>placeBet(item, 'mainMarket' + (sindex + 1), 'X')}><div className="sbb_odd"><img src="images/pc/icon_lock.png" /></div></div>}

                                            <div className={"sports_bet_btn away " + newRunnerSelected(item['MarketDict'], 'mainMarket' + (sindex + 1), '2')} onClick={()=>placeBet(item, 'mainMarket' + (sindex + 1), '2')}>
                                                <div className="sbb_left">
                                                {newGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), 'X') == 3 && <div className="sbb_team">{awayTeam(item)}</div>}
                                                {newGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), 'X') == 2 && <>
                                                    <div className="sbb_team">{newGetName(item['MarketDict'], 'mainMarket' + (sindex + 1), '2', homeTeam(item), awayTeam(item))}</div>
                                                    {newGetName(item['MarketDict'], 'mainMarket' + (sindex + 1), '2', homeTeam(item), awayTeam(item)) == '오버' && <div className="sbb_team_odd_icon"><img src="images/pc/arr1.png" /></div>}
                                                    {newGetName(item['MarketDict'], 'mainMarket' + (sindex + 1), '2', homeTeam(item), awayTeam(item)) == '언더' && <div className="sbb_team_odd_icon"><img src="images/pc/arr2.png" /></div>}
                                                    <div className="sbb_team_odd">{newGetName1(item['MarketDict'], 'mainMarket' + (sindex + 1), '2')}</div>
                                                </>}
                                                </div>
                                                <div className="sbb_right">
                                                {checkBetStatus(item, 'mainMarket' + (sindex + 1)) === '' && <div className="sbb_odd">{nGetOdds(item['MarketDict'], 'mainMarket' + (sindex + 1), '2')}</div>}
                                                {checkBetStatus(item, 'mainMarket' + (sindex + 1)) === ' bet_lock' && <div className="sbb_odd"><img src="images/pc/icon_lock.png" /></div>}
                                                </div>									
                                            </div>
                                        </div>
                                        ))
                                    }

                                </div>
                        ))}
                        </div>
                    }

        </div>
        }
        </>
    )
}

LeagueItem.propTypes = {
    data: PropTypes.array.isRequired,
    combinations: PropTypes.array.isRequired,
    selFixtureId : PropTypes.string.isRequired,
    defaultExpanded: PropTypes.bool,
    viewType: PropTypes.string,
    date: PropTypes.number,
    handleSelectEvent: PropTypes.func,
};
  
export default React.memo(LeagueItem)

import axios from "axios";

import { SERVER_BASE_URL } from "../utils/constant";

const SportAPI = {
    readNavSports: async () => {
      try {
        const response = await axios.get(
          `${SERVER_BASE_URL}/allsports`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },
    
    fetchOutrightData: async(payload) => {
      try {
        const response = await axios.post(
          `${SERVER_BASE_URL}/sport-outright-view`,
          JSON.stringify(payload),
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    fetchWholeEventData: async(payload) => {
      try {
        const response = await axios.post(
          `${SERVER_BASE_URL}/sport-whole-view`,
          JSON.stringify(payload),
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    fetchInitialEventData: async(payload) => {
      try {
        const response = await axios.post(
          `${SERVER_BASE_URL}/sport-init-view`,
          JSON.stringify(payload),
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    fetchEvents: async(payload) => {
      try {
        const response = await axios.post(
          `${SERVER_BASE_URL}/sport-league-view`,
          JSON.stringify(payload),
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    fetchMarkets: async(eventId) => {
      try {
        const response = await axios.get(
          `${SERVER_BASE_URL}/market-view?fixtureid=${eventId}`,
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    fetchSomeMarkets: async(eventId, marketIds) => {
      try {
        const response = await axios.post(
          `${SERVER_BASE_URL}/special-market-view`,
          JSON.stringify({marketIds: marketIds, fixtureId: eventId}),
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

    fetchFavEvents: async(payload) => {
      try {
        const response = await axios.post(
          `${SERVER_BASE_URL}/sport-favorite-view`,
          JSON.stringify(payload),
          {
            headers: {
              "Content-Type": "application/json",
              "auth-token": localStorage.getItem('accessToken')
            },
          }
        );
        return response.data;
      } catch (error) {
        return error.response;
      }
    },

  };
  
  export default SportAPI;
  
import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

const AjaxLoading = (props) => {

    const {
        visible, 
      } = props;

    useEffect(() => {
    }, []);


    return (
        <>
            <div id="loading_mask" style={{zIndex:"10001",display:(visible === true?"block":'none')}}></div>
            <div className="loading_w" style={{top:"0",left:"0", display:(visible === true?"block":'none')}}>
                <div className="loading">
                    <i className="i-loading"></i>
                </div>
            </div>
        </>

    )
}

AjaxLoading.propTypes = {
    visible: PropTypes.bool.isRequired,
};
  
export default React.memo(AjaxLoading)

const initialState = {
  NavData: [],
  LiveEvents: [],
  TotalLiveCount: 0,
  sports: [],
}

function updateNavData(NavData, sportData) {
  if (NavData.length > 0) {
    for (let j = 0; j < sportData.length; j++) {
      let ritem = sportData[j]
      //console.log(ritem);
      if (parseInt(ritem['on_off']) == 1) {
        let found = 0
        for (let i = 0; i < NavData.length; i++) {
          let item = NavData[i]
          if (item['SportId'] == ritem['sid']) found++
        }

        if (found == 0) {
          //console.log('should insert');
          let item = {
            SportId: ritem['sid'],
            SportName: ritem['name'],
            SportName_ko: ritem['show_name'],
            eventsQuantity: 0,
            liveEventsQuantity: 0,
            m_order: ritem['m_order'],
            on_off: ritem['on_off'],
          }

          NavData.push(item)
        }
      }
    }

    for (let i = 0; i < NavData.length; i++) {
      let item = NavData[i]
      //console.log(item);
    }
  }

  for (let i = 0; i < NavData.length; i++) {
    let item = NavData[i]
    if (sportData != null) {
      for (let j = 0; j < sportData.length; j++) {
        let ritem = sportData[j]
        if (item['SportName'] == ritem['name']) {
          item['show_name'] = ritem['show_name']
          item['on_off'] = ritem['on_off']
          item['m_order'] = ritem['m_order']
        }
      }
      if (item['show_name'] == null) {
        item['show_name'] = item['SportName']
        if (item['SportName_ko'] !== null && item['SportName_ko'] !== undefined) {
          item['show_name'] = item['SportName_ko']
        }
        item['on_off'] = 1
        item['m_order'] = 1000
      }
    }
  }

  for (let i = 0; i < NavData.length - 1; i++) {
    for (let j = i + 1; j < NavData.length; j++) {
      if (
        NavData[i]['m_order'] > NavData[j]['m_order'] || 
        (NavData[i]['m_order'] === NavData[j]['m_order'] && NavData[i]['SportId'] < NavData[j]['SportId'])
      ) {
        let item = NavData[i]
        NavData[i] = NavData[j]
        NavData[j] = item
      }
    }
  }

  return NavData
}

export const sockets = (state = initialState, action) => {
  switch (action.type) {
    case 'allsports':
      action.data.sort((a, b) => {
        return a['SportId'] > b['SportId'] === true ? 1 : -1
      })
      let livecount = 0
      action.data.forEach((element) => {
        livecount += element['liveEventsQuantity']
      })
      const data = updateNavData(action.data, state.sports)
      state.NavData = data
      console.log('navData = ', data);
      state.TotalLiveCount = livecount
      return { ...state }
    case 'live-events':
      state.LiveEvents = action.data
      return { ...state }
    case 'setSports':
      state.sports = action.sports
      return { ...state }
    default:
      return { ...state }
  }
}
